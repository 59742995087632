import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Container from 'react-bootstrap/Container'

import ContestantHolder from './components/ConstestantHolder'
import contestants from './contestant-info/48.json'



function App() {
  return (
    <div className="App">
      <Container>
        <ContestantHolder contestants={contestants} />
      </Container>
    </div>
  );
}

export default App;
